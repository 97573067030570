//----------------------------------------------------
// Colours
//----------------------------------------------------

$gold: #ffdb2d;
$grey: #292826;
$grey-light: #545351;
$grey-dark: #1c1c1a;

$link-color: #33f;
$link-color-hover: #306;
$text-color: #333;

$accessibility: pink;

//----------------------------------------------------
//  fonts
//----------------------------------------------------

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-thin-webfont.woff2') format('woff2'),
         url('../fonts/roboto-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$font-sans: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif !default;
$font-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-mono: Monaco, Menlo, Consolas, 'Courier New', monospace !default;
$font-heading: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif !default;

//----------------------------------------------------
//  base typography
//----------------------------------------------------

$font-size: 14px;
$font-size-h1: 30px;
$font-size-h2: 26px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$line-height-base: 1.4;

$font-weight-light: lighter;
$font-weight-regular: normal;
$font-weight-bold: bold;
