.hidden {
    display: none;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
hr,
main {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

blockquote,
q {
    quotes: none;
}

q {
    quotes: '\201C' '\201D' '\2018' '\2019';
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

dfn {
    font-style: italic;
}

hr {
    display: block;
    box-sizing: content-box;
    height: 0;
    border-top: 1px solid #ccc;
}

pre,
code,
kbd,
samp {
    font-family: monospace, sans-serif;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
}

small {
    font-size: 80%;
}

b,
strong,
th {
    font-weight: bold;
}

sup,
sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0;
    padding: 10px;
}

legend {
    border: 0;
    padding: 0;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

button,
input {
    line-height: normal;
}

button,
select {
    text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

input[type='search'] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td {
    vertical-align: top;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/roboto-medium-webfont.woff2") format("woff2"), url("../fonts/roboto-medium-webfont.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/roboto-thin-webfont.woff2") format("woff2"), url("../fonts/roboto-thin-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.trigger-size {
    width: 0;
    height: 0;
    display: none;
}

@media (max-width: 29.99em) {
    .trigger-size.mobile {
        display: block;
    }
}

@media (min-width: 30em) and (max-width: 47.99em) {
    .trigger-size.mobile-large {
        display: block;
    }
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .trigger-size.tablet {
        display: block;
    }
}

@media (min-width: 64em) and (max-width: 87.49em) {
    .trigger-size.desktop {
        display: block;
    }
}

@media (min-width: 87.5em) {
    .trigger-size.desktop-large {
        display: block;
    }
}

body {
    font-family: "Roboto", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    color: #fff;
    background-color: #000;
}

p {
    margin-bottom: 30px;
}

p:last-child {
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: #33f;
}

a:hover {
    color: #306;
}

a:focus,
button:focus,
input:focus,
select:focus,
.button:focus,
textarea:focus,
input[type=checkbox] + label:focus,
input[type=radio] + label:focus,
label:focus {
    outline: 3px inset pink;
}

a.no-focus,
button.no-focus,
input.no-focus,
select.no-focus,
.button.no-focus,
textarea.no-focus,
input[type=checkbox] + label.no-focus,
input[type=radio] + label.no-focus,
label.no-focus {
    outline: 0;
}

.contain {
    margin: 0 auto;
    position: relative;
    max-width: 980px;
    padding-left: 22px;
    padding-right: 22px;
}

.contain.industry-links {
    text-align: center;
}

.site-header {
    background-image: url(../img/header-bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.company .site-header {
    background-image: url(../img/slide1.jpg);
}

.industry-links .site-header {
    background-image: url(../img/slide2.jpg);
}

.contact .site-header {
    background-image: url(../img/slide3.jpg);
}

.in-house-facilities .site-header {
    background-image: url(../img/slide4.jpg);
}

.on-site-services .site-header {
    background-image: url(../img/slide1.jpg);
}

.power-cables .site-header {
    background-image: url(../img/header-bg-power-cables.jpg);
}

.thermal-protection .site-header,
.thermal-protection-sleeves .site-header,
.thermal-protection-blankets .site-header,
.thermal-protection-tapes .site-header,
.thermal-protection-fabricated .site-header {
    background-image: url(../img/header-bg-thermal.jpg);
}

.spares-and-used-equipment .site-header {
    background-image: url(../img/slide1.jpg);
}

.site-header .top-block {
    padding-top: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    position: relative;
    z-index: 2;
}

.site-header .top-block .contain {
    z-index: 2;
}

.site-header .top-block .contain:before, .site-header .top-block .contain:after {
    content: ' ';
    display: table;
}

.site-header .top-block .contain:after {
    clear: both;
}

.site-header .logo {
    float: left;
    margin-bottom: 60px;
}

@media (max-width: 47.99em) {
    .site-header {
        background-position: 30% 184px;
    }
    .site-header .top-block {
        padding-top: 20px;
    }
    .site-header .logo {
        margin-bottom: 20px;
        width: 124px;
        height: 62px;
    }
}

.contact-details {
    margin-top: 30px;
    margin-bottom: 50px;
    float: right;
    clear: right;
    text-align: right;
    font-size: 30px;
    line-height: 1;
    position: relative;
    z-index: 2;
}

.contact-details > span,
.contact-details > a {
    display: block;
}

.contact-details .email-address {
    color: #ffdb2d;
    margin-bottom: 5px;
}

.contact-details .phone-number {
    color: #fff;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .contact-details {
        font-size: 20px;
        margin-bottom: 40px;
    }
}

@media (max-width: 47.99em) {
    .contact-details {
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 26px;
    }
}

.secondary-nav > ul,
.main-nav > ul {
    list-style: none;
}

.secondary-nav a,
.main-nav a {
    display: block;
    transition: .4s color ease;
}

.secondary-nav {
    float: right;
    clear: right;
    position: relative;
    z-index: 2;
}

.secondary-nav > ul:before, .secondary-nav > ul:after {
    content: ' ';
    display: table;
}

.secondary-nav > ul:after {
    clear: both;
}

.secondary-nav > ul > li {
    float: left;
}

.secondary-nav > ul > li:not(:last-child) {
    margin-right: 30px;
}

.secondary-nav a {
    color: #fff;
    font-size: 18px;
}

.secondary-nav a:hover {
    color: #ffdb2d;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .secondary-nav a {
        font-size: 14px;
    }
}

@media (max-width: 47.99em) {
    .secondary-nav {
        float: none;
        clear: both;
        margin-bottom: 20px;
    }
    .secondary-nav > ul {
        display: flex;
        justify-content: space-between;
    }
    .secondary-nav > ul > li {
        float: none;
    }
    .secondary-nav > ul > li:not(:last-child) {
        margin-right: 0;
    }
    .secondary-nav a {
        font-size: 12px;
    }
}

.main-nav {
    background-color: #ffdb2d;
    position: relative;
    position: relative;
    z-index: 3;
}

.main-nav:after {
    content: '';
    display: block;
    width: 100%;
    height: 46px;
    position: absolute;
    top: 100%;
    left: 0;
    background-image: url(../img/white-stripes.png);
    background-repeat: repeat;
    background-position: 0 0;
}

.main-nav a {
    font-size: 20px;
    color: #000;
    font-weight: bold;
}

.main-nav a:hover {
    color: #a09a80;
}

.main-nav > ul > li {
    position: relative;
}

.main-nav > ul > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
}

.main-nav > ul > li > ul {
    position: absolute;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: .4s opacity ease, .4s z-index ease;
    background-color: #ffdb2d;
    width: 290px;
    list-style: none;
    left: -20px;
    top: 100%;
    border-top: 3px solid #000;
}

.main-nav > ul > li > ul > li a {
    padding: 10px 20px;
    transition: .4s color ease, .4s background-color ease;
}

.main-nav > ul > li > ul > li a:hover {
    background-color: #615311;
    color: #fff;
}

.main-nav > ul > li:hover > ul {
    opacity: 1;
    z-index: 100;
    pointer-events: auto;
}

@media (min-width: 48em) {
    .main-nav > ul {
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .main-nav a {
        font-size: 14px;
    }
}

@media (max-width: 47.99em) {
    .main-nav {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
    }
    .main-nav > ul {
        padding-top: 0;
        padding-bottom: 20px;
        position: absolute;
        top: -500px;
        left: 0;
        width: 100%;
        background-color: #ffdb2d;
        z-index: -1;
        opacity: 0;
        transition: .75s top ease, .75s opacity ease, .75s z-index ease;
    }
    .main-nav > ul.active {
        z-index: 100;
        opacity: 1;
        top: 100%;
    }
    .main-nav > ul > li > ul {
        position: relative;
        left: auto;
        top: auto;
        width: 100%;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        z-index: 1;
        opacity: 1;
        pointer-events: auto;
    }
    .main-nav > ul > li > ul > li a {
        font-size: 14px;
    }
    .main-nav > ul > li:hover > ul {
        z-index: 1;
    }
    .main-nav a {
        font-size: 16px;
        text-align: center;
        padding: 10px 0;
    }
}

.mobile-nav-toggle {
    border: 0;
    padding: 15px 22px;
    text-align: center;
    background-color: #ffdb2d;
    margin: 0;
    width: 100%;
    line-height: 1;
    clear: both;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    display: none;
}

.mobile-nav-toggle:after {
    content: '>';
    display: block;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translate(0, -50%) rotate(90deg);
    width: 20px;
    height: 20px;
    font-size: 20px;
    transition: .4s transform ease;
}

.mobile-nav-toggle.active:after {
    transform: translate(0, -50%) rotate(-90deg);
}

@media (max-width: 47.99em) {
    .mobile-nav-toggle {
        display: block;
    }
}

.logo {
    display: block;
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
    width: 264px;
    height: 136px;
    background-color: transparent;
    background-image: url("../img/logo.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .logo {
        width: 204px;
        height: 101px;
    }
}

.page-title {
    margin-top: 300px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 2;
}

.page-title h1 {
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    color: #fff;
    font-size: 60px;
    line-height: 1;
    font-weight: normal;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .page-title {
        margin-top: 100px;
    }
    .page-title h1 {
        font-size: 40px;
    }
}

@media (max-width: 47.99em) {
    .page-title {
        margin-top: 100px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .page-title h1 {
        font-size: 30px;
    }
}

.fader,
.fader-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
}

.fader {
    z-index: 1;
}

.fader-slide {
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    transition: 3s opacity ease;
    opacity: 0;
}

.fader-slide.fader-slide-active {
    opacity: 1;
}

.fader-slide-1 {
    background-image: url(../img/slide1.jpg);
}

.fader-slide-2 {
    background-image: url(../img/slide2.jpg);
}

.fader-slide-3 {
    background-image: url(../img/slide3.jpg);
}

.fader-slide-4 {
    background-image: url(../img/slide4.jpg);
}

.fader-intros {
    margin-top: 400px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 2;
    height: 600px;
}

.fader-intros p {
    margin-bottom: 0;
    text-align: center;
    color: #ffdb2d;
    font-size: 46px;
    line-height: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 8px solid #fff;
    border-bottom: 8px solid #fff;
    font-weight: bold;
    transition: 1.5s opacity ease;
    opacity: 0;
    height: 400px;
    width: 89.79592%;
    max-width: 880px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
}

.fader-intros p.fader-intro-active {
    opacity: 1;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .fader-intros {
        margin-top: 300px;
        padding-top: 60px;
        padding-bottom: 60px;
        height: 360px;
    }
    .fader-intros p {
        border-width: 6px 0;
        font-size: 30px;
        line-height: 1.1;
        height: 240px;
        top: 60px;
    }
}

@media (max-width: 47.99em) {
    .fader-intros {
        margin-top: 184px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 160px;
    }
    .fader-intros p {
        border-width: 4px 0;
        font-size: 16px;
        line-height: 1.1;
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 120px;
        top: 20px;
    }
}

.site-footer {
    position: relative;
    background-image: url(../img/footer-bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 22px;
    padding-right: 22px;
}

.site-footer .logo,
.site-footer p {
    position: relative;
    z-index: 3;
}

.site-footer p {
    margin: 0;
    color: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 1;
}

.site-footer p span:first-child {
    margin-right: 30px;
}

.site-footer .logo {
    width: 176px;
    height: 92px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 34px;
}

.site-footer:before, .site-footer:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.site-footer:before {
    height: 46px;
    top: 0;
    background-image: url(../img/white-stripes.png);
    background-repeat: repeat;
    background-position: 0 0;
    z-index: 2;
}

.site-footer:after {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .site-footer {
        padding-bottom: 60px;
    }
    .site-footer p {
        font-size: 16px;
    }
    .site-footer p span {
        display: block;
        margin-bottom: 10px;
    }
    .site-footer p span:first-child {
        margin-right: 0;
    }
}

@media (max-width: 47.99em) {
    .site-footer {
        padding-bottom: 40px;
    }
    .site-footer .logo {
        display: inline-block;
        width: 126px;
        height: 62px;
    }
    .site-footer p {
        font-size: 12px;
    }
    .site-footer p span {
        display: block;
        margin-bottom: 10px;
    }
    .site-footer p span:first-child {
        margin-right: 20px;
    }
}

.sub-title {
    background-color: #1c1c1a;
    padding-top: 30px;
    padding-bottom: 30px;
}

.sub-title h2 {
    text-align: center;
    font-size: 42px;
}

@media (max-width: 47.99em) {
    .sub-title {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .sub-title h2 {
        font-size: 20px;
    }
}

main {
    position: relative;
    z-index: 2;
    background-color: #1c1c1a;
}

.index main {
    margin-top: 706px;
}

.in-house-facilities main,
.on-site-services main,
.company main,
.power-cables main {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
}

.in-house-facilities main {
    background-image: url(../img/content-bg2.jpg);
}

.on-site-services main {
    background-image: url(../img/content-bg3.jpg);
}

.company main {
    background-image: url(../img/content-bg4.jpg);
}

.power-cables main {
    background-image: url(../img/in-house-content-bg.png);
}

.spares-and-used-equipment main {
    background-image: url(../img/content-bg4.jpg);
}

main h2,
main h3,
main h4,
main h5,
main h6 {
    color: #ffdb2d;
    font-weight: normal;
    margin: 0 0 20px 0;
}

main h2 {
    font-size: 30px;
}

main h3 {
    font-size: 26px;
}

main h4 {
    font-size: 24px;
}

main h5,
main h6 {
    font-size: 22px;
}

main article.contain {
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 880px;
    font-size: 20px;
}

main article.contain a {
    color: #ffdb2d;
    transition: .4s color ease;
}

main article.contain a:hover {
    color: #fff;
}

main article.contain ul {
    padding: 0 0 0 24px;
    margin: 20px 0 30px 0;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    main article.contain {
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: 22px;
        margin-right: 22px;
        font-size: 18px;
    }
}

@media (max-width: 47.99em) {
    main h2 {
        font-size: 20px;
    }
    main h3 {
        font-size: 18px;
    }
    main h4 {
        font-size: 16px;
    }
    main h5,
    main h6 {
        font-size: 14px;
    }
    main article.contain {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
        font-size: 14px;
    }
}

.lead-para {
    color: #ffdb2d;
    font-size: 34px;
    line-height: 111.76471%;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .lead-para {
        font-size: 24px;
        line-height: 1.1;
    }
}

@media (max-width: 47.99em) {
    .lead-para {
        font-size: 16px;
        line-height: 1.1;
    }
}

.standout {
    color: #ffdb2d;
}

.contact-form {
    max-width: 590px;
    margin-top: 60px;
}

.contact-form fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.contact-form p {
    margin: 0 0 20px 0;
}

.contact-form label {
    display: block;
    color: #ffdb2d;
    font-size: 20px;
    margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
    border: 0;
    border-radius: 6px;
    background-color: #545351;
    padding: 10px;
    color: #fff;
    width: 100%;
    transition: .4s color ease, .4s background-color ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    background-color: #fff;
    color: #000;
    outline: 0;
}

.contact-form textarea {
    height: 140px;
}

.contact-form button {
    background: transparent;
    border: 0;
    padding: 0;
    color: #ffdb2d;
    font-size: 20px;
    transition: .4s color ease;
}

.contact-form button:hover {
    color: #fff;
}

@media (min-width: 48em) and (max-width: 63.99em) {
    .contact-form label,
    .contact-form button {
        font-size: 18px;
    }
}

@media (max-width: 47.99em) {
    .contact-form {
        margin-top: 30px;
    }
    .contact-form label,
    .contact-form button {
        font-size: 14px;
    }
}

.contact-map {
    width: 100%;
    height: 400px;
    position: relative;
}

.product-block {
    margin-top: 100px;
    margin-bottom: 100px;
}

.product-block:before, .product-block:after {
    content: ' ';
    display: table;
}

.product-block:after {
    clear: both;
}

.product-block:first-of-type {
    margin-top: 0;
}

.product-block > h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #545351;
    margin-bottom: 40px;
}

@media (max-width: 47.99em) {
    .product-block {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .product-block > h2 {
        margin-bottom: 20px;
        font-size: 18px;
    }
}

.product-image {
    float: left;
    width: 40%;
    margin-bottom: 40px;
}

.product-image img {
    vertical-align: bottom;
    width: 100%;
}

@media (max-width: 47.99em) {
    .product-image {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .product-image img {
        width: auto;
        max-width: 100%;
    }
}

.product-overview {
    width: 50%;
    float: right;
    margin-bottom: 40px;
}

@media (max-width: 47.99em) {
    .product-overview {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
}

.product-spec {
    clear: both;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #545351;
    border-bottom: 1px solid #545351;
    margin-bottom: 20px;
}

.product-spec a {
    display: inline-block;
    padding-left: 45px;
    line-height: 35px;
    background-image: url(../img/download.png);
    background-size: auto 35px;
    background-position: 0 0;
    background-repeat: no-repeat;
}

.product-spec a + a {
    margin-left: 30px;
}

@media (max-width: 47.99em) {
    .product-spec a {
        display: block;
        background-size: auto 20px;
        padding-left: 25px;
        line-height: 20px;
    }
    .product-spec a + a {
        margin-left: 0;
        margin-top: 20px;
    }
}

.product-description {
    clear: both;
}
