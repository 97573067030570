//----------------------------------------------------
//      main block
//----------------------------------------------------

.sub-title {
    background-color: $grey-dark;
    padding-top:  30px;
    padding-bottom: 30px;

    h2 {
        text-align: center;
        font-size: 42px;
    }

    @include mq($until: tablet) {
        padding-top: 10px;
        padding-bottom: 10px;

        h2 {
            font-size: 20px;
        }
    }
}

main {
    position: relative;
    z-index: 2;
    background-color: $grey-dark;

    .index & {
        margin-top: 706px;
    }

    .in-house-facilities &,
    .on-site-services &,
    .company &,
    .power-cables & {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
    }

    .in-house-facilities & {
        background-image: url(../img/content-bg2.jpg);
    }

    .on-site-services & {
        background-image: url(../img/content-bg3.jpg);
    }

    .company & {
        background-image: url(../img/content-bg4.jpg);
    }

    .power-cables & {
        background-image: url(../img/in-house-content-bg.png);
    }

    .spares-and-used-equipment & {
        background-image: url(../img/content-bg4.jpg);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $gold;
        font-weight: normal;
        margin: 0 0 20px 0;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 24px;
    }

    h5,
    h6 {
        font-size: 22px;
    }

    article.contain {
        padding-top: 100px;
        padding-bottom: 100px;
        max-width: 880px;
        font-size: 20px;

        a {
            color: $gold;
            transition: .4s color ease;

            &:hover {
                color: #fff;
            }
        }

        ul {
            padding: 0 0 0 24px;
            margin: 20px 0 30px 0;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        article.contain {
            padding-top: 60px;
            padding-bottom: 60px;
            margin-left: 22px;
            margin-right: 22px;
            font-size: 18px;
        }
    }

    @include mq($until: tablet) {
            h2 {
                font-size: 20px;
            }

            h3 {
                font-size: 18px;
            }

            h4 {
                font-size: 16px;
            }

            h5,
            h6 {
                font-size: 14px;
            }

        article.contain {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-left: 0;
            margin-right: 0;
            font-size: 14px;
        }
    }
}

.lead-para {
    color: $gold;
    font-size: 34px;
    line-height: (38/34)*100%;

    @include mq($from: tablet, $until: desktop) {
        font-size: 24px;
        line-height: 1.1;
    }

    @include mq($until: tablet) {
        font-size: 16px;
        line-height: 1.1;
    }
}
