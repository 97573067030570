//----------------------------------------------------
//      footer
//----------------------------------------------------

.site-footer {
    position: relative;
    background-image: url(../img/footer-bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 22px;
    padding-right: 22px;

    .logo,
    p {
        position: relative;
        z-index: 3;
    }

    p {
        margin: 0;
        color: #fff;
        text-align: center;
        font-size: 18px;
        line-height: 1;

        span {
            &:first-child {
                margin-right: 30px;
            }
        }
    }

    .logo {
        width: 176px;
        height: 92px;
        position: relative;
        left: 50%;
        transform: translate(-50%,0);
        margin-bottom: 34px;
    }

    &:before,
    &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &:before {
        height: 46px;
        top: 0;
        background-image: url(../img/white-stripes.png);
        background-repeat: repeat;
        background-position: 0 0;
        z-index: 2;
    }

    &:after {
        height: 100%;
        background-color: rgba(0,0,0,.8);
        z-index: 1;
    }

    @include mq($from: tablet, $until: desktop) {
        padding-bottom: 60px;

        p {
            font-size: 16px;

            span {
                display: block;
                margin-bottom: 10px;

                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }

    @include mq($until: tablet) {
        padding-bottom: 40px;

        .logo {
            display: inline-block;
            width: 126px;
            height: 62px;
        }

        p {
            font-size: 12px;

            span {
                display: block;
                margin-bottom: 10px;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
