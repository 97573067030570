//----------------------------------------------------
//      product block
//----------------------------------------------------

.product-block {
    @include cf;
    margin-top: 100px;
    margin-bottom: 100px;

    &:first-of-type {
        margin-top: 0;
    }

    > h2 {
        padding-bottom: 10px;
        border-bottom: 1px solid $grey-light;
        margin-bottom: 40px;
    }

    @include mq($until: tablet) {
        margin-top: 20px;
        margin-bottom: 40px;

        > h2 {
            margin-bottom: 20px;
            font-size: 18px;
        }
    }
}

.product-image {
    float: left;
    width: 40%;
    margin-bottom: 40px;

    img {
        vertical-align: bottom;
        width: 100%;
    }

    @include mq($until: tablet) {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        img {
            width: auto;
            max-width: 100%;
        }
    }
}

.product-overview {
    width: 50%;
    float: right;
    margin-bottom: 40px;

    @include mq($until: tablet) {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
}

.product-spec {
    clear: both;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    margin-bottom: 20px;

    a {
        display: inline-block;
        padding-left: 45px;
        line-height: 35px;
        background-image: url(../img/download.png);
        background-size: auto 35px;
        background-position: 0 0;
        background-repeat: no-repeat;

        & + a {
            margin-left: 30px;
        }
    }

    @include mq($until: tablet) {
        a {
            display: block;
            background-size: auto 20px;
            padding-left: 25px;
            line-height: 20px;

            & + a {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}

.product-description {
    clear: both;
}
