//----------------------------------------------------
//      contact
//----------------------------------------------------

.standout {
    color: $gold;
}

.contact-form {
    max-width: 590px;
    margin-top: 60px;

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    p {
        margin: 0 0 20px 0;
    }

    label {
        display: block;
        color: $gold;
        font-size: 20px;
        margin-bottom: 10px;
    }

    input,
    textarea {
        border: 0;
        border-radius: 6px;
        background-color: $grey-light;
        padding: 10px;
        color: #fff;
        width: 100%;
        transition: .4s color ease, .4s background-color ease;

        &:focus {
            background-color: #fff;
            color: #000;
            outline: 0;
        }
    }

    textarea {
        height: 140px;
    }

    button {
        background: transparent;
        border: 0;
        padding: 0;
        color: $gold;
        font-size: 20px;
        transition: .4s color ease;

        &:hover {
            color: #fff;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        label,
        button {
            font-size: 18px;
        }
    }

    @include mq($until: tablet) {
        margin-top: 30px;

        label,
        button {
            font-size: 14px;
        }
    }
}

.contact-map {
    width: 100%;
    height: 400px;
    position: relative;
}
