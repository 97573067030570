//----------------------------------------------------
//      header
//----------------------------------------------------

.site-header {
    background-image: url(../img/header-bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;

    .company & {
        background-image: url(../img/slide1.jpg);
    }

    .industry-links & {
        background-image: url(../img/slide2.jpg);
    }

    .contact & {
        background-image: url(../img/slide3.jpg);
    }

    .in-house-facilities & {
        background-image: url(../img/slide4.jpg);
    }

    .on-site-services & {
        background-image: url(../img/slide1.jpg);
    }

    .power-cables & {
        background-image: url(../img/header-bg-power-cables.jpg);
    }

    .thermal-protection &,
    .thermal-protection-sleeves &,
    .thermal-protection-blankets &,
    .thermal-protection-tapes &,
    .thermal-protection-fabricated & {
        background-image: url(../img/header-bg-thermal.jpg);
    }

    .spares-and-used-equipment & {
        background-image: url(../img/slide1.jpg);
    }

    .top-block {
        padding-top: 30px;
        background-color: rgba(0,0,0,.7);
        position: relative;
        z-index: 2;

        .contain {
            @include cf;
            z-index: 2;
        }
    }

    .logo {
        float: left;
        margin-bottom: 60px;
    }

    @include mq($until: tablet) {
        background-position: 30% 184px;

        .top-block {
            padding-top: 20px;
        }

        .logo {
            margin-bottom: 20px;
            width: 124px;
            height: 62px;
        }
    }
}

.contact-details {
    margin-top: 30px;
    margin-bottom: 50px;
    float: right;
    clear: right;
    text-align: right;
    font-size: 30px;
    line-height: 1;
    position: relative;
    z-index: 2;

    > span,
    > a {
        display: block;
    }

    .email-address {
        color: $gold;
        margin-bottom: 5px;
    }

    .phone-number {
        color: #fff;
    }

    @include mq($from: tablet, $until: desktop) {
        font-size: 20px;
        margin-bottom: 40px;
    }

    @include mq($until: tablet) {
        font-size: 12px;
        margin-bottom: 20px;
        margin-top: 26px;
    }
}

.secondary-nav,
.main-nav {
    > ul {
        list-style: none;
    }

    a {
        display: block;
        transition: .4s color ease;
    }
}

.secondary-nav {
    float: right;
    clear: right;
    position: relative;
    z-index: 2;

    > ul {
        @include cf;

        > li {
            float: left;
            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    a {
        color: #fff;
        font-size: 18px;

        &:hover {
            color: $gold;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        a {
            font-size: 14px;
        }
    }

    @include mq($until: tablet) {
        float: none;
        clear: both;
        margin-bottom: 20px;

        > ul {
            display: flex;
            justify-content: space-between;

            > li {
                float: none;

                &:not(:last-child) {
                    margin-right: 0;
                }
            }
        }

        a {
            font-size: 12px;
        }
    }
}

.main-nav {
    background-color: $gold;
    position: relative;
    position: relative;
    z-index: 3;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 46px;
        position: absolute;
        top: 100%;
        left: 0;
        background-image: url(../img/white-stripes.png);
        background-repeat: repeat;
        background-position: 0 0;
    }

    a {
        font-size: 20px;
        color: #000;
        font-weight: bold;

        &:hover {
            color: #a09a80;
        }
    }

    > ul {
        > li {
            position: relative;

            > a {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            > ul {
                position: absolute;
                opacity: 0;
                z-index: -1;
                pointer-events: none;
                transition: .4s opacity ease, .4s z-index ease;
                background-color: $gold;
                width: 290px;
                list-style: none;
                left: -20px;
                top: 100%;
                border-top: 3px solid #000;

                > li {
                    a {
                        padding: 10px 20px;
                        transition: .4s color ease, .4s background-color ease;

                        &:hover {
                            background-color: #615311;
                            color: #fff;
                        }
                    }
                }
            }

            &:hover {
                > ul {
                    opacity: 1;
                    z-index: 100;
                    pointer-events: auto;
                }
            }
        }
    }

    @include mq($from: tablet) {
        > ul {
            display: flex;
            justify-content: space-between;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        a {
            font-size: 14px;
        }
    }

    @include mq($until: tablet) {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        > ul {
            padding-top: 0;
            padding-bottom: 20px;
            position: absolute;
            top: -500px;
            left: 0;
            width: 100%;
            background-color: $gold;
            z-index: -1;
            opacity: 0;
            transition: .75s top ease, .75s opacity ease, .75s z-index ease;

            &.active {
                z-index: 100;
                opacity: 1;
                top: 100%;
            }

            > li {
                > ul {
                    position: relative;
                    left: auto;
                    top: auto;
                    width: 100%;
                    border-top: 2px solid #000;
                    border-bottom: 2px solid #000;
                    z-index: 1;
                    opacity: 1;
                    pointer-events: auto;

                    > li {
                        a {
                            font-size: 14px;
                        }
                    }
                }

                &:hover {
                    > ul {
                        z-index: 1;
                    }
                }
            }
        }

        a {
            font-size: 16px;
            text-align: center;
            padding: 10px 0;
        }
    }
}

.mobile-nav-toggle {
    border: 0;
    padding: 15px 22px;
    text-align: center;
    background-color: $gold;
    margin: 0;
    width: 100%;
    line-height: 1;
    clear: both;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    display: none;

    &:after {
        content: '>';
        display: block;
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translate(0,-50%) rotate(90deg);
        width: 20px;
        height: 20px;
        font-size: 20px;
        transition: .4s transform ease;
    }

    &.active {
        &:after {
            transform: translate(0,-50%) rotate(-90deg);
        }
    }

    @include mq($until: tablet) {
        display: block;
    }
}

.logo {
    @include imgreplace(264px, 136px, '../img/logo.png');

    @include mq($from: tablet, $until: desktop) {
        width: 204px;
        height: 101px;
    }
}

.page-title {
    margin-top: 300px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: rgba(0,0,0,.8);
    position: relative;
    z-index: 2;

    h1 {
        margin-bottom: 0;
        margin-top: 0;
        text-align: center;
        color: #fff;
        font-size: 60px;
        line-height: 1;
        font-weight: normal;
    }

    @include mq($from: tablet, $until: desktop) {
        margin-top: 100px;

        h1 {
            font-size: 40px;
        }
    }

    @include mq($until: tablet) {
        margin-top: 100px;
        padding-top: 20px;
        padding-bottom: 20px;

        h1 {
            font-size: 30px;
        }
    }
}

//----------------------------------------------------
//      homepage fading backgrounds
//----------------------------------------------------

.fader,
.fader-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
}

.fader {
    z-index: 1;
}

.fader-slide {
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    transition: 3s opacity ease;
    opacity: 0;

    &.fader-slide-active {
        opacity: 1;
    }
}

.fader-slide-1 {
    background-image: url(../img/slide1.jpg);
}

.fader-slide-2 {
    background-image: url(../img/slide2.jpg);
}

.fader-slide-3 {
    background-image: url(../img/slide3.jpg);
}

.fader-slide-4 {
    background-image: url(../img/slide4.jpg);
}

.fader-intros {
    margin-top: 400px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0,0,0,.8);
    position: relative;
    z-index: 2;
    height: 600px;

    p {
        margin-bottom: 0;
        text-align: center;
        color: $gold;
        font-size: 46px;
        line-height: 1;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 8px solid #fff;
        border-bottom: 8px solid #fff;
        font-weight: bold;
        transition: 1.5s opacity ease;
        opacity: 0;
        height: 400px;
        width: (880/980)*100%;
        max-width: 880px;
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translate(-50%,0);

        &.fader-intro-active {
            opacity: 1;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        margin-top: 300px;
        padding-top: 60px;
        padding-bottom: 60px;
        height: 360px;

        p {
            border-width: 6px 0;
            font-size: 30px;
            line-height: 1.1;
            height: 240px;
            top: 60px;
        }
    }

    @include mq($until: tablet) {
        margin-top: 184px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 160px;

        p {
            border-width: 4px 0;
            font-size: 16px;
            line-height: 1.1;
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            height: 120px;
            top: 20px;
        }
    }
}
