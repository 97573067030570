//----------------------------------------------------
//      basic typography
//----------------------------------------------------

body {
    font-family: $font-sans;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: $line-height-base;
    color: #fff;
    background-color: #000;
}

p {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}

//----------------------------------------------------
//  other base styles
//----------------------------------------------------

a {
    text-decoration: none;
    color: $link-color;

    &:hover {
        color: $link-color-hover;
    }
}

//----------------------------------------------------
//      accessibliity thing
//----------------------------------------------------

a,
button,
input,
select,
.button,
textarea,
input[type=checkbox] + label,
input[type=radio] + label,
label {
    &:focus {
        outline: 3px inset $accessibility;
    }

    &.no-focus {
        outline: 0;
    }
}

//----------------------------------------------------
//      containers
//----------------------------------------------------

.contain {
    margin: 0 auto;
    position: relative;
    max-width: 980px;
    padding-left: 22px;
    padding-right: 22px;

    &.industry-links {
        text-align: center;
    }
}
